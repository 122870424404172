<template>
  <div>
    <v-container fluid>

      <v-row>

        <v-col cols="12" lg="8" class="d-flex flex-column">
          <Furnitures class="flex d-flex flex-column"/>
        </v-col>

        <v-col cols="12" lg="4" class="d-flex flex-column">

          <v-card class="rounded-lg shadow  flex d-flex flex-column">
            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="article"/>
            </v-card-text>
            <div v-else>
              <v-card-title>
                Types des pos
                <v-spacer/>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="text-center">
                <div v-if="typeAnalytic">
                  <Doughnut :data="typeAnalytic"
                            :style="{position: 'relative', height:'30vh'}"/>
                </div>
              </v-card-text>
            </div>
          </v-card>

          <v-card class="rounded-lg shadow mt-5 flex d-flex flex-column">
            <v-card-text v-if="isLoading">
              <v-skeleton-loader type="article"/>
            </v-card-text>
            <div v-else>
              <v-card-title>
                Classes des pos
                <v-spacer/>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="text-center">
                <div v-if="classAnalytic">
                  <PieChart :data="classAnalytic"
                            :style="{position: 'relative', height:'30vh'}"/>
                </div>
              </v-card-text>
            </div>
          </v-card>
        </v-col>

      </v-row>


      <v-row>

        <v-col cols="12" lg="5" class="d-flex flex-column">
          <CampaignsList class="flex d-flex flex-column"/>
        </v-col>

        <v-col cols="12" lg="4" class="d-flex flex-column">
          <TasksList class="flex d-flex flex-column"/>
        </v-col>

        <v-col cols="12" lg="3" class="d-flex flex-column">
          <TipAccount class="flex d-flex flex-column"/>
        </v-col>

      </v-row>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import CampaignsList from "./components/CampaignsList";
import Doughnut from "../../components/Chart/DoughnutChart";
import PieChart from "../../components/Chart/PieChart";
import TasksList from "./components/TasksList";
import TipAccount from "./components/TipAccount";
import Furnitures from "./components/Furnitures";

export default {
  components: {Furnitures, TipAccount, TasksList, PieChart, Doughnut, CampaignsList},
  data() {
    return {
      isLoading: false,
      typeAnalytic: null,
      classAnalytic: null,
    }
  },
  methods: {
    elementsAnalytic() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('dashboard/analytic/elements').then((res) => {
        this.isLoading = false
        this.$Progress.finish()

        this.typeAnalytic = res.data.data.types
        this.classAnalytic = res.data.data.classes

      }).catch(err => {
        this.isLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
  },
  created() {
    this.elementsAnalytic()
  },
}
</script>

<style scoped>

</style>