<template>
  <div>

    <v-card class="rounded-lg shadow flex d-flex flex-column"
            v-if="isLoading"
            color="primary">
      <v-card-text>
        <v-skeleton-loader type="text,text,avatar"/>
        <v-skeleton-loader class="mt-5" type="text,text,avatar"/>
      </v-card-text>
    </v-card>

    <v-card class="rounded-lg shadow flex d-flex flex-column"
            v-else :img="require('@/assets/shiny.svg')"
            color="primary"
            dark>
      <v-card-title>
        Comptes TIP
      </v-card-title>
      <v-card-text class="pa-0">

        <v-list-item two-line>
          <v-list-item-avatar color="white" size="50">
            <v-icon color="primary">mdi-account-check-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Active</v-list-item-title>
            <v-list-item-subtitle>{{ activeTotal }} Pos</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <span class="font-weight-bold">{{ activePercent }} %</span>
          </v-list-item-action>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar color="white" size="50">
            <v-icon color="primary">mdi-account-off-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Inactive</v-list-item-title>
            <v-list-item-subtitle>{{ notActiveTotal }} Pos</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <span class="font-weight-bold">{{ notActivePercent }} %</span>
          </v-list-item-action>
        </v-list-item>
        <h1 class="text-center white--text mt-5">
          {{ total }}
        </h1>
        <v-sparkline
            :value="sparkLine"
            color="white"
            height="100"
            padding="24"
            stroke-linecap="round"
            smooth
            auto-draw
        ></v-sparkline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      sparkLine: [423, 446, 675, 510, 590, 610, 400, 800],
      isLoading: false,
      total: null,
      activeTotal: null,
      activePercent: null,
      notActiveTotal: null,
      notActivePercent: null,
    }
  },
  methods: {
    accountsAnalytic() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('dashboard/analytic/accounts').then((res) => {
        this.isLoading = false
        this.$Progress.finish()

        this.total = res.data.data.total
        this.activeTotal = res.data.data.activeTotal
        this.activePercent = res.data.data.activePercent
        this.notActiveTotal = res.data.data.notActiveTotal
        this.notActivePercent = res.data.data.notActivePercent

      }).catch(err => {
        this.isLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
  },
  created() {
    this.accountsAnalytic()
  }
}
</script>

<style scoped>

</style>