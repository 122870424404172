<template>
  <div>
    <v-card class="rounded-lg shadow  flex d-flex flex-column">
      <v-card-text v-if="isLoading">
        <v-skeleton-loader type="image,image"/>
      </v-card-text>
      <div v-else>
        <v-card-title>
          Meubles des pos
          <v-spacer/>
          <v-btn color="blue" depressed dark class="text-none"
                 @click="ExportExcel">
            Télécharger
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <BarChart :data="{label:false, labels: furnitureKeys,data: furnitures}"/>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import BarChart from "../../../components/Chart/BarChart";
import {HTTP} from "@/http-common";

export default {
  components: {BarChart},
  data() {
    return {
      isLoading: false,
      furnitures: null,
    }
  },
  methods: {
    furnituresAnalytic() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('dashboard/analytic/furnitures').then((res) => {
        this.isLoading = false
        this.$Progress.finish()

        this.furnitures = res.data.data
      }).catch(err => {
        this.isLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    ExportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/database?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
  },
  created() {
    this.furnituresAnalytic()
  },
  computed: {
    furnitureKeys() {
      let keyword = this.$store.state.keyword
      return [
        keyword.key_10,
        keyword.key_11,
        keyword.key_12,
        keyword.key_13,
        keyword.key_14,
        keyword.key_15,
        keyword.key_16,
        keyword.key_17,
        keyword.key_18,
        keyword.key_19,
        keyword.key_20,
        keyword.key_21,
        keyword.key_22,
        keyword.key_23,
        keyword.key_24,
        keyword.key_25,
      ]
    }
  }
}
</script>

<style scoped>

</style>