<template>
  <div>
    <v-card class="rounded-lg shadow flex d-flex flex-column">

      <v-card-text v-if="isLoading">
        <v-skeleton-loader type="table" height="280"/>
      </v-card-text>

      <div v-else>
        <v-card-title>Campagnes actives</v-card-title>
        <v-card-text :class="campaigns.length === 0 ? '' : 'pa-0'">

          <div v-if="campaigns.length === 0">
            <div class="text-center">
              <v-avatar tile height="150" width="150">
                <v-img :src="require('@/assets/campaigns.svg')"/>
              </v-avatar>
            </div>
            <h4 class="text-center text--secondary mt-4">
              Actuellement Aucune campagne active.
            </h4>
          </div>

          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Image</th>
                <th>Campagne</th>
                <th width="200">Progression</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(campaign,i) in campaigns" :key="i">
                <td>
                  <v-avatar rounded size="35">
                    <v-img v-if="campaign.photo"
                           :src="FILE_URL + campaign.photo"/>
                    <v-img v-else :src="require('@/assets/no-image.png')" class="img-border"/>
                  </v-avatar>
                </td>
                <td>{{ campaign.name }}</td>
                <td class="text-right">
                  <div class="d-flex justify-space-between">
                    <span> {{ campaign.tasksCompleted }} / {{ campaign.tasksTotal }}</span>
                    <v-spacer/>
                    <span>{{ campaign.tasksPercent }}%</span>
                  </div>
                  <v-progress-linear
                      rounded
                      height="6"
                      striped
                      :value="campaign.tasksPercent"
                      :color="$func.progressColor(campaign.tasksPercent)"
                  >
                  </v-progress-linear>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider v-if="campaigns.length"/>

        </v-card-text>
        <v-card-actions class="card--action--bottom">
          <v-btn block
                 rounded
                 depressed
                 color="blue"
                 dark
                 class="mt-5"
                 to="/campaigns">
            Liste des campagnes
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      isLoading: false,
      campaigns: null,
    }
  },
  methods: {
    campaignsAnalytic() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('dashboard/analytic/campaigns').then((res) => {
        this.isLoading = false
        this.$Progress.finish()

        this.campaigns = res.data.data.data
      }).catch(err => {
        this.isLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
  },
  created() {
    this.campaignsAnalytic()
  }
}
</script>

<style>
.card--action--bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>