<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData"/>
</template>

<script>
import {Bar} from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)
ChartJS.defaults.font.family = "Quicksand";
export default {
  name: 'BarChart',
  components: {Bar},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartData: {
        labels: this.data.labels,
        datasets: [{
          label: this.data.label,
          data: this.data.data,
          backgroundColor: "#3DB2FF",
          borderColor: "#3DB2FF",
          borderRadius: 50,
        }]
      },
      chartOptions: {
        responsive: true,
        aspectRatio: 5 / 4,
        layout: {
          padding: {
            top: 45
          }
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            max: 100,
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              labelPointStyle: function () {
                return {
                  pointStyle: 'rounded',
                };
              }
            }
          },
          datalabels: {
            align: 'end',
            anchor: 'end',
            borderRadius: 4,
            color: 'white',
            rotation: -40,
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            padding: 4,
            value: function (context) {
              return context + '%';
            },
          }
        },
      }
    }
  },
}
</script>
