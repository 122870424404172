<template>
  <div>
    <v-card class="rounded-lg shadow flex d-flex flex-column">

      <v-card-text v-if="isLoading">
        <v-skeleton-loader type="table" height="280"/>
      </v-card-text>

      <div v-else>
        <v-card-title>Tâches actives</v-card-title>
        <v-card-text :class="tasks.length === 0 ? '' : 'pa-0'">
          <div v-if="tasks.length === 0">
            <div class="text-center mb-8">
              <v-avatar tile height="150" width="150">
                <v-img :src="require('@/assets/tasks.svg')"/>
              </v-avatar>
            </div>
            <v-alert text
                     border="left"
                     prominent
                     color="info">
              Actuellement Aucune tâche active.
            </v-alert>
          </div>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
              <tr>
                <th>Tâche</th>
                <th class="text-center">Total</th>
                <th>Pourcentage</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(task,i) in tasks" :key="i">
                <td>
                  {{ task.name }}
                </td>
                <td class="text-center">
                  <strong> {{ task.sp_progress.total }}</strong>
                </td>
                <td class="text-right">
                  <strong class="d-block">
                    {{ task.sp_progress.progress }}%
                    <v-icon right
                            size="20"
                            :color="$func.progressColor(task.sp_progress.progress)">
                      mdi-chevron-up
                    </v-icon>
                  </strong>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider v-if="tasks.length"/>

        </v-card-text>
      </div>

    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      tasks: null,
    }
  },
  methods: {
    tasksAnalytic() {
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('dashboard/analytic/tasks').then((res) => {
        this.isLoading = false
        this.$Progress.finish()

        this.tasks = res.data.data.data

      }).catch(err => {
        this.isLoading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
  },
  created() {
    this.tasksAnalytic()
  }
}
</script>

<style>
</style>