<template>
  <Pie :chart-options="chartOptions" :chart-data="chartData"/>
</template>

<script>
import {Pie} from 'vue-chartjs/legacy'
import {ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale)
ChartJS.defaults.font.family = "Quicksand";

export default {
  name: 'PieChart',
  components: {Pie},
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartData: {
        labels: this.data.labels,
        datasets: [{
          backgroundColor: ["rgba(61,178,255)", "rgba(61,178,255,0.8)", "rgba(61,178,255,0.6)", "rgba(61,178,255,0.4)"],
          data: this.data.data,
          borderColor: "rgba(0,0,0,0)",
          hoverOffset: 4
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 10
            },
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              labelPointStyle: function () {
                return {
                  pointStyle: 'rounded',
                };
              },
            }
          },
          datalabels: {
            display : true,
            color: 'white'
          }
        },
      }
    }
  },
}
</script>
